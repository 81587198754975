.bg-post {
	.bg-post-main {
		background-color: #E8E6DC;
	}
}
.bg-card_split {
	font-size: 1.15em;
	.bg-card_split-text {
		margin-bottom: 1.5em;
		@media (min-width: 1024px) {
			flex: 1 1 auto;
			margin-right: 2rem;
			margin-bottom: unset;
			text-align: right;
		}
		@media (min-width: 1200px) {
			flex: 0 0 17.5em;
		}
		p {
			&:last-child {
				@media (min-width: 1024px) {
					margin-bottom: unset;
				}
			}
		}
	}
	.bg-card_split-cards {
		@media (min-width: 1024px) {
			flex: 0 0 60%;
		}
		@media (min-width: 1200px) {
			flex: 1 1 auto;
		}
	}
	.bg--content_area {
		@media (min-width: 1024px) {
			display: flex;
			align-items: center;
		}
	}
}
.bg-card_nav {
	.bg--button {
		background: #973825;
	}
	ul {
		display: flex;
		flex-direction: column;
		align-items: center;
		list-style: none;
		position: relative;
		@media (min-width: 768px) {
			flex-direction: row;
			margin-bottom: unset;
		}
	}
	li {
		margin-bottom: 1.50em;
		@media (min-width: 768px) {
			margin-bottom: unset;
		}
		&:last-child {
			margin-bottom: unset;
		}
		&:nth-child(1) {
			@media (min-width: 768px) {
				margin-top: -1em;
				animation: 1s fade-in-top-relative;
			}
		}
		&:nth-child(2) {
			@media (min-width: 768px) {
				margin-top: 4em;
				animation: 1s fade-in-bottom-relative;
			}
		}
		&:nth-child(3) {
			@media (min-width: 768px) {
				margin-top: -4em;
				animation: 1s fade-in-top-relative;
			}
		}
	}
	a {
		display: grid;
		transition: box-shadow 0.15s, transform 0.15s;
		z-index: 0;
		&:focus,
		&:hover {
			z-index: 100;
			transform: translateY(-12px);
			box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
		}
	}
	img {
		display: block;
		max-width: 100%;
		@media (min-width: 768px) {
			height: 14em;
			object-fit: cover;
		}
	}
	.bg--button {
		justify-self: end;
		align-self: start;
		margin: {
			top: calc((-1em * 1.50) - 0.50em - 1em);
			right: 1em;
		}
	}
}
